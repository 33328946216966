
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _global from '../api/global';
import {
    faBoxOpen,
    faCartShopping,
    faDollarSign,
    faClipboardList,
    faCubes,
    faObjectGroup,
    faShuffle,
    faUserGroup,
    faCogs,
    faUserCog,
    faCog,
    faHomeUser,
    faBoxesAlt,
    faWarehouse,
    faTruck,
    faCartFlatbedSuitcase,
    faTruckRampBox,
    faCartFlatbed,
    faChartLine,
    faChartColumn,
    faSliders,
    faRoute,
    faPeopleArrows,
    faFileInvoiceDollar,
    faWallet
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';




const SideBar = () => {
    const { t } = useTranslation();
    const { innerWidth: width, innerHeight: height } = window;

    const [collapseProduct, setCollapseProduct] = useState(width < 768 ? '' : 'show');
    const [enableAdmin, setEnableAdmin] = useState(false);

    useEffect(() => {
        try {
            if (_global.getToken().type?.permissions?.includes("admin")) {
                setEnableAdmin(true)
            } else {
                setEnableAdmin(false)
            }
        } catch (e) {
            localStorage.removeItem('token');
        }

    }, [])

    const navClick = () => {

    }

    const activeMenuProduct = (isActive) => {
        if (isActive) {
            setCollapseProduct('show')
            return 'collapse-item active';
        } else {
            setCollapseProduct('')
            return 'collapse-item';
        }
    }

    //document.body.classList.add('modal-open');
    return (

        <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${width < 768 ? 'toggled' : ''} disabled-print`} id="accordionSidebar">
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
                <div className="sidebar-brand-text mx-3">Thammasak <sup>tm</sup></div>
            </a>
            <hr className="sidebar-divider my-0" />
            <li className="nav-item active">
                <Link to={`/`} className="nav-link">
                    <i className="fas fa-fw fa-tachometer-alt" />
                    <span>Dashboard</span>
                </Link>
            </li>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">
                Interface
            </div>

            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCart" aria-expanded="true" aria-controls="collapseUtilities">
                    <FontAwesomeIcon icon={faClipboardList} />
                    <span> {t('sale/rent')}</span>
                </a>
                <div id="collapseCart" className={`collapse ${collapseProduct}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">{t('sale/rent')}:</h6>
                        <NavLink to="/orders" className="collapse-item" >
                            <FontAwesomeIcon icon={faCartShopping} className="text-primary" /> {t('Orders')}
                        </NavLink>
                        <NavLink to={`/customer/list`} className="collapse-item" >
                            <FontAwesomeIcon icon={faUserGroup} className="text-primary" /> {t('Customers')}
                        </NavLink>
                        <NavLink to="/sale/report" className="collapse-item" >
                            <FontAwesomeIcon icon={faChartLine} className="text-success" /> {t('Sale Report')}
                        </NavLink>
                    </div>
                </div>
            </li>

            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCart" aria-expanded="true" aria-controls="collapseUtilities">
                    <FontAwesomeIcon icon={faClipboardList} />
                    <span> {t('financial')}</span>
                </a>
                <div id="collapseCart" className={`collapse ${collapseProduct}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">{t('sale/rent')}:</h6>
                        <NavLink to="/finance/billing-note" className="collapse-item" >
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-warning" /> {t('billing note')}/{t('delivery note')}
                        </NavLink>
                        <NavLink to="/finance/receipt" className="collapse-item" >
                            <FontAwesomeIcon icon={faWallet} className="text-info" /> {t('Receipt')}
                        </NavLink>
                    </div>
                </div>
            </li>


            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseInven" aria-expanded="true" aria-controls="collapseUtilities">
                    <FontAwesomeIcon icon={faWarehouse} />
                    <span> {t('warehouse')}</span>
                </a>
                <div id="collapseInven" className={`collapse ${collapseProduct}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">{t('warehouse')}:</h6>

                        <NavLink to="/inventory/journal" className="collapse-item" >
                            <FontAwesomeIcon icon={faCartFlatbed} className="text-info" /> {t('item journal')}
                        </NavLink>

                        <NavLink to="/inventory/return" className="collapse-item" >
                            <FontAwesomeIcon icon={faTruckRampBox} className="text-warning" /> {t('return receive')}
                        </NavLink>

                        <NavLink to="/inventory/adjust/receive" className="collapse-item" >
                            <FontAwesomeIcon icon={faRoute} className="text-success" /> {t('receive journal')}
                        </NavLink>

                        <NavLink to="/inventory/adjust/stock" className="collapse-item" >
                            <FontAwesomeIcon icon={faSliders} className="text-danger" /> {t('inventory adjust')}
                        </NavLink>


                        <h6 className="collapse-header"> {t('report')}</h6>
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink to="/inventory" className="collapse-item" >
                                <FontAwesomeIcon icon={faChartColumn} className="text-primary" /> {t('transactions')}
                            </NavLink>
                        </div>

                    </div>
                </div>



            </li>


            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseProduct" aria-expanded="true" aria-controls="collapseTwo">
                    <FontAwesomeIcon icon={faBoxOpen} />
                    <span> {t('Products')}</span>
                </a>
                <div id="collapseProduct" className={`collapse ${collapseProduct}`} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">{t('Product Setting')}:</h6>
                        <NavLink to={`/products/list`} className="collapse-item">
                            <FontAwesomeIcon icon={faCubes} className="text-info" /> {t('Products')}
                        </NavLink>
                        <NavLink to={`/category/list`} className="collapse-item" >
                            <FontAwesomeIcon icon={faObjectGroup} className="text-warning" /> {t('Category')}
                        </NavLink>
                        <NavLink to={`/measure`} className="collapse-item" >
                            <FontAwesomeIcon icon={faShuffle} className="text-success" /> {t('Measurement')}
                        </NavLink>

                    </div>
                </div>
            </li>

            {
                enableAdmin && <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAdmin" aria-expanded="true" aria-controls="collapseTwo">
                        <FontAwesomeIcon icon={faCogs} />
                        <span> {t('Administrator')}</span>
                    </a>
                    <div id="collapseAdmin" className={`collapse ${collapseProduct}`} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">{t('System Setting')}:</h6>
                            <NavLink to={`/user/list`} className="collapse-item">
                                <FontAwesomeIcon icon={faUserCog} className="text-primary" /> {t('Users')}
                            </NavLink>
                            <NavLink to={`/approve/setup`} className="collapse-item">
                                <FontAwesomeIcon icon={faPeopleArrows} className="text-warning" /> {t('Approve setup')}
                            </NavLink>
                            <NavLink to={`/config`} className="collapse-item">
                                <FontAwesomeIcon icon={faCog} className="text-danger" /> {t('Settings')}
                            </NavLink>
                            <NavLink to={`/company/list`} className="collapse-item">
                                <FontAwesomeIcon icon={faHomeUser} className="text-info" /> {t('company')}
                            </NavLink>
                        </div>
                    </div>
                </li>
            }


            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle" onClick={navClick} />
            </div>

        </ul>

    )
}

export default SideBar; 