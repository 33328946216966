import React, { useEffect, useState } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import base32 from "hi-base32";
import _global from './global';
import auth from './auth';

const get = async (id) => {
    try {
        if (!id) { throw ('user id not found') }
        const response = await _global.client.get(`/users/${id}`);

        let data = {
            ...response.data,
            settings: response.data.settings ? JSON.parse(response.data.settings) : []
        };

        // let encodeData = base32.encode(JSON.stringify(data));
        // let encrypted = await auth.pef1.encrypt(JSON.stringify(data), '1234');
        // console.log('pef1 encrypt', encrypted);
        // console.log('pef1 decrypt', await auth.pef1.decrypt(encrypted, '1234'));
        //localStorage.setItem('cached', encodeData);

        return data;
    } catch (e) {
        console.warn(e, id);
        return [];
    }
}


const getUserPosition = async (position) => {
    try {
        let response = {};
        if (position === 'sales') {
            response = { data: [await get(_global.getToken().id)] };
            let userList = await _global.client.get(`/users/department/${position}`);
            response.data.push(...userList.data);
            //console.log('getUserPosition response::', response)
        } else {
            response = await _global.client.get(`/users/department/${position}`);
        }

        return response;
    } catch (error) {
        console.error(error);
    }
}

const getData = async () => {
    try {
        let response = await _global.client.get(`/users`);
        //console.log('response::', response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

const updateData = async (body, id) => {
    try {
        if (body.passwd) {
            await _global.client.put(`/users/${id}/passwd`, {
                "username": body.username,
                "password": body.passwd,
                "repassword": body.passwd
            });
            delete body.passwd;
        }
        delete body.interrupt;
        return await _global.client.patch(`/users/${id}`, body);
    } catch (e) {
        console.error('error::', e);
    }
}

const remove = async (id) => {
    try {
        return await _global.client.delete(`/users/${id}`);
    } catch (e) {
        console.error('error::', e);
    }
}


const register = async (params) => {
    try {
        let body = {
            "f_name_th": params.f_name_th || params.name.split(' ')[0] || '',
            "l_name_th": params.l_name_th || params.name.split(' ')[1] || '',
            "phone": params.phone,
            "username": params.username || params.email,
            "password": params.password,
            "email": params.email,
            "facebook_auth": "",
            "google_auth": "",
            "permissions": JSON.stringify(['user']),
            "rules": "",
            "settings": JSON.stringify({ address: "" }),
            "avatar": "",
            "comp": params.comp || _global.getToken().comp,
            "status": params.status,
            "position": params.position,
            "domain": params.domain || '*'
        }
        //console.log('>>> POST', body);
        const response = await _global.client.post(`/users`, body);
        //console.log('<<< RES::', response);
        return response.data;
    } catch (error) {
        console.error('error register::', error);
    }
}

const login = async (param) => {
    try {
        let body = {
            "username": param.user,
            "password": param.password
        }
        //console.log('>>> POST login', body);
        const response = await _global.client.post(`/login`, body);
        //console.log('<<< login RES::', response);
        return response;
    } catch (e) {
        console.error('<<< ERROR LOGIN', e);
        return {
            status: e.status,
            message: e.data?.message || e.message
        }
    }
}

const update = async (params, id) => {
    try {
        let body = {
            ...params,
            settings: Object.keys(params.settings).length ? JSON.stringify(params.settings) : ""
        }
        //console.log('>>> PATCH update', body);
        const response = await _global.client.patch(`/users/${id}`, body);
        //console.log('<<< update RES::', response);
        return response;
    } catch (e) {
        console.error('<<< ERROR update', e);
        return {
            status: e.status,
            message: e.message
        }
    }
}

const FormAddData = ({ data, setData, setLoading, formData, setFormData, t, setEditable, editable, company, setNotify, notify }) => {

    const [password, setPassword] = useState('');
    const [passwdNotify, setPasswdNotify] = useState({ class: 'border-warning', notify: 'd-none' });

    useEffect(() => {

    }, [data])

    const confirmPassword = (value) => {
        if (value !== password) {
            setPasswdNotify({
                class: 'border-danger',
                notify: 'd-block'
            })
            setFormData({ ...formData, passwd: null, interrupt: true });
        } else {
            setPasswdNotify({
                class: 'border-success',
                notify: 'd-none'
            })
            setFormData({ ...formData, passwd: password, interrupt: false });
            const err = notify;
            if (err) {
                delete err.errors?.passwd;
                setNotify({ errors: err });
            }

        }
    }


    console.log('formData::', formData)
    console.log('notify::', notify)
    return <div>
        <div className="">
            <div className="row">
                <div className="col-6">
                    <label htmlFor='product-price'>{t('username')}:</label>
                    <input className="form-control" readOnly={editable ? false : true} value={formData.username || ''} id={'username'} onChange={e => setFormData({ ...formData, username: e.target.value })} />
                    { // show error message
                        notify.errors?.username && <label className='text-danger'>{t('username require')}</label>
                    }

                    <div className='mt-4'>
                        <label htmlFor='email'>{t('email')}:</label>
                        <input className={"form-control"}
                            value={formData.email || ''}
                            id={'email'}
                            onChange={e => setFormData({ ...formData, email: e.target.value })}
                        // placeholder={notify.errors?.email && t('email require')}
                        />
                        { // show error message
                            notify.errors?.email && <label className='text-danger'>{t('email require')}</label>
                        }
                    </div>
                </div>

                <div className="col-12 col-sm-6  ">


                    <div className={`card mt-4 ${passwdNotify.class}`}>
                        <div className="card-body  p-3">
                            <label htmlFor='validatedCustomFile' className="text-info">{formData.id && t('change password')}</label>
                            <div className="custom-file">
                                <label htmlFor='password'>{t('password')}:</label>
                                <input type="password" className="form-control" placeholder='******' id={'password'} onChange={el => setPassword(el.target.value)} />
                                { // show error message
                                    !formData.id && notify.errors?.passwd && <div><label className='text-danger'>{t('password require')}</label> </div>
                                }

                                <label htmlFor='confirm-password' className='mt-2'>{t('confirm password')}:</label>
                                <input type="password" className="form-control" placeholder='******' id={'confirm-password'} onChange={el => confirmPassword(el.target.value)} />
                                <label className={`text-danger ${passwdNotify.notify}`}>{t('password not match')}</label>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="col-12 mt-4">
                    <div className={`card mt-4`}>
                        <div className="card-body  p-3">
                            <div className="row">
                                <div className='col-6'>
                                    <label htmlFor='user-f_name_th'>{t('name')}:</label>
                                    <input className="form-control mb-3" value={formData.f_name_th || ''} id={'user-f_name_th'} onChange={e => setFormData({ ...formData, f_name_th: e.target.value })} />



                                    <label htmlFor='user-l_name_th'>{t('last name')}:</label>
                                    <input className="form-control mb-3" value={formData.l_name_th || ''} id={'user-l_name_th'} onChange={e => setFormData({ ...formData, l_name_th: e.target.value })} />

                                </div>
                                <div className='col-6'>

                                    <label htmlFor='user-number'>{t('phone')}:</label>
                                    <input className="form-control mb-3" value={formData.phone || ''} id={'user-number'} onChange={e => setFormData({ ...formData, phone: e.target.value })} />

                                    <label htmlFor='product-position '>{t('Department')}:</label>
                                    <select
                                        className="form-control mb-3"
                                        value={formData.position || ''}
                                        id={'product-position'}
                                        onChange={e => setFormData({ ...formData, position: e.target.value })} >
                                        <option value=''></option>
                                        <option value='store'>{t('store')}</option>
                                        <option value='sales'>{t('sales')}</option>
                                    </select>

                                    <label htmlFor='user-company '>{t('Company')}:</label>

                                    <select
                                        className="form-control mb-3"
                                        value={formData.comp || ''}
                                        id={'user-company'}
                                        onChange={e => setFormData({ ...formData, comp: e.target.value })} >
                                        {
                                            company.length && company.map((el, index) =>
                                                <option value={el.id} key={index + 1}>{el.name + ' ' + el.branch_name}</option>
                                            )
                                        }
                                    </select>


                                    <div className="mt-3">
                                        <label htmlFor='product-Status' className='mr-2'>{t('Status')}   </label>
                                        <BootstrapSwitchButton
                                            checked={formData.status === 1 ? true : false}
                                            onlabel={t('Active')}
                                            offlabel={t('Disable')}
                                            onChange={(checked) => { setFormData({ ...formData, status: checked ? 1 : 0 }) }}
                                            size="sm"
                                            width={100}
                                            onstyle="success"
                                            id='product-Status'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


const UserApi = {
    get,
    getUserPosition,
    getData,
    remove,
    register,
    login,
    update,
    updateData,
    FormAddData
};

export default UserApi;