import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import _global from '../api/global';
import CompanyApi from '../api/company';




function TopBar() {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [userInfo, setUserInfo] = useState('');
    const [company, setCompany] = useState([]);

    const fetchData = async () => {
        try {
            const [tokenData, companyData] = await Promise.all([
                _global.getToken(),
                localStorage.getItem('companies')
                    ? JSON.parse(localStorage.getItem('companies'))
                    : CompanyApi.search('')
            ]);
            
            setUserInfo(tokenData);
            setName(tokenData.sub);

            if (Array.isArray(companyData)) {
                setCompany(companyData);
            } else {
                setCompany(companyData.data);
                localStorage.setItem("companies", JSON.stringify(companyData.data));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const companyProfile = () => {
        return company?.filter((com) => com.id === userInfo?.comp)[0]
    }

    useEffect(() => {
        fetchData();
    }, []);

    //console.log('userInfo-->:', userInfo)
    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            {/* Sidebar Toggle (Topbar) */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars" />
            </button>
            {/* Topbar Search */}
            <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button">
                            <i className="fas fa-search fa-sm" />
                        </button>
                    </div>
                </div>
            </form>
            {/* Topbar Navbar */}
            <ul className="navbar-nav ml-auto">
                {/* Nav Item - Search Dropdown (Visible Only XS) */}
                <li className="nav-item dropdown no-arrow d-sm-none">
                    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-search fa-fw" />
                    </a>
                    {/* Dropdown - Messages */}
                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                        <form className="form-inline mr-auto w-100 navbar-search">
                            <div className="input-group">
                                <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button">
                                        <i className="fas fa-search fa-sm" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>
                {/* Nav Item - Alerts */}
                <li className="nav-item dropdown no-arrow mx-1">
                    <div className="nav-link dropdown-toggle" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {
                            companyProfile()?.name + ' ' +companyProfile()?.branch_name
                        }
                    </div>
                </li>
                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-bell fa-fw" />
                        {/* Counter - Alerts */}
                        {/* <span className="badge badge-danger badge-counter">3+</span> */}
                    </a>
                    {/* Dropdown - Alerts */}

                </li>
                {/* Nav Item - Messages */}
                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-envelope fa-fw" />
                        {/* Counter - Messages */}
                        {/* <span className="badge badge-danger badge-counter">7</span> */}
                    </a>
                    {/* Dropdown - Messages */}

                </li>
                <div className="topbar-divider d-none d-sm-block" />
                {/* Nav Item - User Information */}
                <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userInfo?.info?.name + " " + userInfo?.info?.surname}</span>
                        <img className="img-profile rounded-circle" src="/assets/img/undraw_profile.svg" />
                    </a>
                    {/* Dropdown - User Information */}
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                        <Link className="dropdown-item" to={`/pofile`}>
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Profile')}
                        </Link>
                        <Link className="dropdown-item" to={`/config`}>
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Settings')}
                        </Link>
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Activity Log')}
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                            {t('Logout')}
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default TopBar;