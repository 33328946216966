import {
    faUserCircle,
    faMessage,
    faHomeAlt,
    faCartPlus
} from '@fortawesome/free-solid-svg-icons'

export default  {
    api: 'https://api.thammasak.co.th', //'https://thammasak-api.vercel.app', //'https://api.thammasak.co.th',
    domain: "backend.thammasak.co.th",
    auth: {
        user: "guest",
        pass: "11111",
    },
    cert: {
        key: "key.pem",
        cert: "cert.pem",
        ca: "csr.pem"
    },
    google: {
        client_id: '1038066089925-447gftt3j4hpai6c85sml25t27t1pp94.apps.googleusercontent.com'
    },
    menu: [
        {
            id: 1,
            icon: faHomeAlt,
            text: "_home",
            url: "/"
        },
        {
            id: 2,
            icon: faMessage,
            text: "_message",
            url: "/"
        },
        {
            id: 3,
            icon: faCartPlus,
            text: "_cart",
            url: "/cart"
        },
        {
            id: 4,
            icon: faUserCircle,
            text: "_acc",
            url: "/login"
        }
    ],


    category: [
        {
            id: 1,
            img: 'นั่งร้าน.jpg',
            title: 'อุปกรณ์ก่อสร้าง',
            alt: "",
            list: [
                "นั่งร้าน",
                "แบบเหล็ก",
                "เหล็กกล่องค้ำยัน",
                "อื่นๆอีกมากมาย"
            ]
        },
        {
            id: 2,
            img: 'crane.jpg',
            title: 'เครื่องจักร',
            alt: "เครื่องจักร รถเครน",
            list: [
                "รถเครน",
                "รถเฮี๊ยบ",
                "รถแบคโฮ",
                "รถกระบะ",
                "รถบรรทุก",
                "รถกระเข้าไฟฟ้า"
            ]
        },
        {
            id: 3,
            img: 'office-box.png',
            title: 'สำนักงานและเครื่องมือช่าง',
            alt: "สำนักงานและเครื่องมือช่าง",
            list: [
                "ตู้นำนักงานเคลื่อนที่พร้อมเครื่องปรับอากาศ",
                "ห้องน้ำสำนักงานเคลื่อนที่",
                "เครื่องแย๊กปูนย",
                "เครื่องดัดเหล็ก",
                "เครื่องตัดเหล็ก",
                "เครื่องปั่นไฟ"
            ]
        },
    ],

    items: [
        {
            id: 1,
            img: '10ล้อเฮี๊ยบ.jpg',
            title: 'รถเฮี๊ยบ',
            alt: "",
            description: 'รถเฮี๊ยบ',
            url: '',
            price: {
                day: '10,000',
                month: '30,000',
                years: '100,000'
            },
            category: 2,
            properties: [
                { name: 'start_date', title: 'วันที่เริ่มต้น', value: 'MM/DD/YY' },
                { name: 'end_date', title: 'วันที่สิ้นสุด', value: 'end_date' },
                { name: 'days', title: 'จำนวนวันที่เช่า', value: '', endLabel: 'วัน' },
                { name: 'location', title: 'เลือก Location หน้างาน', value: '' },
                { name: '' , title: '', value: ''}
            ]
        },
        {
            id: 2,
            img: 'นั่งร้าน.jpg',
            title: 'นั่งร้าน',
            alt: "",
            description: 'นั่งร้าน',
            url: '',
            price: {
                day: '10,000',
                month: '30,000',
                years: '100,000'
            },
            category: 1
        },
        {
            id: 3,
            img: 'crane.jpg',
            title: 'เครน',
            alt: "",
            description: 'เครน',
            url: '',
            price: {
                day: '10,000',
                month: '30,000',
                years: '100,000'
            },
            category: 2
        },
        {
            id: 4,
            img: 'backhoe.jpg',
            title: 'แบคโฮล',
            alt: "",
            description: 'แบคโฮล',
            url: '',
            price: {
                day: '10,000',
                month: '30,000',
                years: '100,000'
            },
            category: 2
        },
        {
            id: 5,
            img: 'office-box.png',
            title: 'ตู้สำนักงาน',
            alt: "ออฟฟิศสำเร็จรูป ตู้สำนักงาน ตู้ออฟฟิศสนามสำเร็จรูป",
            description: 'ออฟฟิศสำเร็จรูป ',
            url: '',
            price: {
                day: '10,000',
                month: '30,000',
                years: '100,000'
            },
            category: 3
        },
        {
            id: 6,
            img: 'steel.png',
            title: 'แบบเหล็ก',
            alt: "แบบเหล็ก",
            description: 'แบบเหล็กมาตรฐาน ',
            url: '',
            price: {
                day: '10,000',
                month: '30,000',
                years: '100,000'
            },
            category: 1
        },
        {
            id: 7,
            img: 'box-steel.jpg',
            title: 'เหล็กกล่อง',
            alt: "เหล็กกล่อง",
            description: 'เหล็กกล่อง',
            url: '',
            price: {
                day: '10,000',
                month: '32,000',
                years: '100,000'
            },
            category: 1
        }
    ]

}